import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import style from './ConversationEditor.module.css'
import { Conversation } from '../../modules/messenger/models/Conversation'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users/container'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { IUserService } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import {
  ConversationDTO,
  emptyConversationDTO,
  fromModel,
  toModel,
} from '../../modules/messenger/models/ConversationDTO'
import close from '../../assets/articles/delete.svg'
import { Header } from '../../components/header/Header'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { Query, QueryParam } from 'common/api/Query'
import { emptyUserDTO, UserDTO, UserQuery } from 'modules/users/models/User'
import { Input } from '../resource/userProfileStylesMui'

type NewConversationProps = {
  conversation?: Conversation
  handleClose: () => void
  handleSave: (c: Conversation, isUpdate: boolean, participantID: string) => void
  conversationCollection?: Conversation[]
}

type AutocompleteUserInterface = {
  key: string
  value: string
}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

// const themeStyles = makeStyles(() => ({
//   input: {
//     padding: 0,
//     '& .MuiAutocomplete-inputRoot': {
//       padding: 0,
//       '& .MuiOutlinedInput-root': {
//         padding: 0,
//       },
//     },
//     '& .MuiAutocomplete-input': {
//       padding: 0,
//     },
//     '& .MuiOutlinedInput-root': {
//       padding: 0,
//     },
//     '&.Mui-focused': {
//       '& fieldset.MuiOutlinedInput-notchedOutline': {
//         borderColor: '#a9ba37 !important',
//       },
//     },
//   },
//   inputUnderline: {
//     '&:after': {
//       borderColor: '#a9ba37 !important',
//     },
//   },
//   selectInput: {
//     '& ': {
//       width: '29rem !important',
//     },

//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#a9ba37 !important',
//     },
//   },
//   MuiAutocomplete: {
//     inputRoot: {
//       '&&[class*="MuiOutlinedInput-root"] $input': {
//         padding: 1,
//       },
//     },
//   },

//   label: {
//     [`&.${inputLabelClasses.shrink}`]: {
//       color: '#a9ba37',
//       marginTop: '-1%',
//       backgroundColor: 'white',
//       borderColor: '#a9ba37',
//     },
//   },
// }))

// const NoPaddingAutocomplete = withStyles({
//   inputRoot: {
//     '& .MuiInputBase-root': {
//       padding: 0,
//     },
//     '&&[class*="MuiOutlinedInput-root"] $input': {
//       paddingTop: 1,
//       paddingLeft: 1,
//       paddingRight: 1,
//       paddingBottom: 2,
//     },
//     '& .MuiOutlinedInput-notchedOutline': {
//       borderColor: 'green',
//     },
//     '&:hover .MuiOutlinedInput-notchedOutline': {
//       borderColor: 'red',
//     },
//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: 'purple',
//     },
//   },
//   input: {},
// })(Autocomplete)

export const ConversationEditor = (props: NewConversationProps): JSX.Element => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [conversation, setConversation] = useState<ConversationDTO>(
    props.conversation ? fromModel(props.conversation) : emptyConversationDTO(loggedUser?.id || '')
  )
  const [selectedUser, setSelectedUser] = useState<AutocompleteUserInterface>()
  const [possibleParticipants, setPossibleParticipants] = useState<
    Map<string, AutocompleteUserInterface>
  >(new Map())

  const [user, setUser] = useState<UserDTO>(emptyUserDTO())
  const [patient, setPatient] = useState<UserDTO>(emptyUserDTO())
  const [doctor, setDoctor] = useState<UserDTO>(emptyUserDTO())

  useEffect(() => {
    if (user && user.isPatient) {
      if (user.assignedID == '') return
      userService.getByID(user.assignedID).subscribe((res1) => {
        console.log('estoy aqui cuando ocurre', res1, user.assignedID)
        if (!res1) return
        const newMap = new Map()
        newMap.set(res1.id, { key: res1.id, value: `${res1.firstName} ${res1.lastName}` })
        setPossibleParticipants(newMap)
      })
    } else {
      userService
        .getFilteredList(
          new Query({
            query: [new QueryParam<UserQuery>('assignedID', loggedUserService.get()?.id || '')],
          })
        )
        .subscribe((res) => {
          const newMap = new Map()
          res.items.forEach((u, i) => {
            if (props.conversationCollection) {
              const filter = props.conversationCollection.filter((item) => u.id === item.userID)
              if (!filter.length) {
                newMap.set(u.id, { key: u.id, value: `${u.firstName} ${u.lastName}` })
              }
            }
          })
          setPossibleParticipants(newMap)
        })
    }
  }, [user])

  useEffect(() => {
    if (!loggedUser) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      if (!res) return
      setUser(res)
      if (res.isPatient) {
        setPatient(res)
      } else {
        setDoctor(res)
      }
    })
  }, [loggedUser])

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setConversation(Object.assign({ ...conversation }, { [e.target.name]: e.target.value }))
  }

  const saveConversation = (e: FormEvent<HTMLFormElement>) => {
    console.log(e)
    e.preventDefault()

    if (!user || !doctor || !selectedUser) return
    //console.log('user', user)
    const newConversation: ConversationDTO = {
      ...conversation,
      name: user.isPatient ? doctor.firstName : selectedUser.value,
    }
    props.handleSave(
      toModel(newConversation),
      props.conversation !== undefined,
      selectedUser?.key || ''
    )
  }

  const handleSave = () => {
    // console.log(selectedUser)
    const newConversation = Object.assign({ ...conversation }, { name: conversation.name })
    //console.log(newConversation)
    props.handleSave(toModel(newConversation), false, selectedUser?.key || '')
  }

  // const classes = themeStyles()
  return (
    <Box className={style.newConversationContainer}>
      <Header
        label={t(props.conversation ? 'editConversation' : 'newConversation')}
        icon={close}
        onClick={props.handleClose}
      />
      <form onSubmit={saveConversation}>
        <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
          <p className={style.label}>{t('name')}</p>
          <Input
            key={'name'}
            id={'name'}
            name={'name'}
            value={conversation.name}
            onChange={handleInput}
            required={true}
            size={'small'}
            variant={'outlined'}
            fullWidth={true}
            // InputLabelProps={{ className: classes.label }}
            // InputProps={{ className: classes.input }}
          />
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <p className={style.label}>{t('participant')}</p>
          <Autocomplete
            className={style.autocomplete}
            style={{ width: '100%', borderRadius: '32px', padding: 0 }}
            key={conversation.id}
            id={`${conversation.id}-standard`}
            value={selectedUser}
            options={Array.from(possibleParticipants.values()) as AutocompleteUserInterface[]}
            //@ts-ignore
            getOptionLabel={(opt) => t(opt.value)}
            onChange={(_: any, v: any) => {
              //@ts-ignore
              v && setSelectedUser(v)
            }}
            renderInput={(params: any) => <Input {...params} variant={'outlined'} />}
            noOptionsText={t('noElementsAvailable')}
            size={'small'}
          />
        </Box>
      </form>
      <Box display={'flex'} justifyContent={'space-between'} />
      <Box display={'flex'} justifyContent={'center'} width={'100%'} style={{ marginTop: '8%' }}>
        <Box mr={4}>
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('close')}
            handler={props.handleClose}
          />
        </Box>
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'submit'}
          label={t('save')}
          handler={handleSave}
        />
      </Box>
    </Box>
  )
}
