import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'

import { useTranslation } from 'react-i18next'
import StatisticsBox from '../../components/statisticsBox/StatisticsBox'
import { Box } from '@mui/material'
import { getContentContainer } from '../../container/statistics-module'
import {
  IUserCaloriesService,
  IUserStepsService,
  IUserHeartService,
  IUserIntensityService,
  IUserSleepService,
  IUserSpO2Service,
  USER_CALORIES_SERVICE_KEY,
  USER_STEPS_SERVICE_KEY,
  USER_SLEEP_SERVICE_KEY,
  USER_SPO2_SERVICE_KEY,
  USER_HEART_SERVICE_KEY,
  USER_INTENSITY_SERVICE_KEY,
} from '../../modules/statistics'
import { Query } from '../../common/api/Query'
import { StatisticsQuery as S, Statistics as ST } from '../../modules/statistics/models/Statistics'
import { Intensity as I } from '../../modules/statistics/models/Intensity'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { emptyList, ItemList } from '../../common/models/ItemList'
import genericStyle from '../../common/utils/generic.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'

type StatisticsProps = {
  id: string
  stylesText?: string
  stylesHistorial?: string
  styleInfoBox?: string
  dashboard?: boolean
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const stepData = [[12578, 8352, 13299, 6344, 8347, 6876, 14591]]
export const intensityData = [
  [611, 711, 635, 816, 789, 781, 713],
  [251, 215, 278, 178, 232, 220, 246],
  [9, 3, 19, 8, 6, 12, 3],
  [38, 7, 19, 1, 32, 8, 46],
] /*
export const heartRateData = [[52, 50, 52, 53, 55, 57, 58]]
export const floorsData = [[4, 6, 29, 10, 6, 13, 26]]
export const sleepData = [[6, 7, 4, 8, 9, 6, 5]]
export const caloriesData = [[2747, 2502, 2849, 2297, 2650, 2503, 3063]]

 */

export function Statistics(props: StatisticsProps) {
  const { t } = useTranslation()

  const [calories, setCalories] = useState<ItemList<ST>>(emptyList())
  const [steps, setSteps] = useState<ItemList<ST>>(emptyList())
  const [sleep, setSleep] = useState<ItemList<ST>>(emptyList())
  const [spO2, setSpO2] = useState<ItemList<ST>>(emptyList())
  const [heart, setHeart] = useState<ItemList<ST>>(emptyList())
  const [intensity, setIntensity] = useState<ItemList<I>>(emptyList())

  const caloriesService = getContentContainer().get<IUserCaloriesService>(USER_CALORIES_SERVICE_KEY)
  const stepsService = getContentContainer().get<IUserStepsService>(USER_STEPS_SERVICE_KEY)
  const sleepService = getContentContainer().get<IUserSleepService>(USER_SLEEP_SERVICE_KEY)
  const spO2Service = getContentContainer().get<IUserSpO2Service>(USER_SPO2_SERVICE_KEY)
  const heartService = getContentContainer().get<IUserHeartService>(USER_HEART_SERVICE_KEY)
  const intensityService = getContentContainer().get<IUserIntensityService>(
    USER_INTENSITY_SERVICE_KEY
  )
  const loggedUser = loggedUserService.get()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  useEffect(() => {
    if (isLoading && loggedUser?.id) {
      caloriesService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'userId',
                value: props.id ? props.id : loggedUser.id || '',
              },
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setCalories(res)
        })
      stepsService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'userId',
                value: props.id ? props.id : loggedUser.id || '',
              },
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setSteps(res)
        })

      sleepService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'userId',
                value: props.id ? props.id : loggedUser.id || '',
              },
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setSleep(res)
        })

      spO2Service
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'userId',
                value: props.id ? props.id : loggedUser.id || '',
              },
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setSpO2(res)
        })

      heartService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'userId',
                value: props.id ? props.id : loggedUser.id || '',
              },
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setHeart(res)
        })

      intensityService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'userId',
                value: props.id ? props.id : loggedUser.id || '',
              },
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setIntensity(res)
        })
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: props.dashboard ? '' : openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        className={props.dashboard ? '' : genericStyle.pageContainer}
        style={{
          maxWidth: props.dashboard
            ? ''
            : openMenuLeft
            ? 'calc(100% - 250px)'
            : 'calc(100% - 300px)',
          height: props.dashboard ? '' : '100vh',
          marginBottom: '95px',
        }}
      >
        <Box
          style={{
            height: props.dashboard ? '' : '100vh',
            paddingBottom: 20,
            background: 'transparent',
            boxShadow: 'none',
          }}
          justifyContent={'flex-start'}
          className={genericStyle.container}
        >
          <Grid
            container
            pt={4}
            pl={4}
            pr={4}
            spacing={{ xs: 4, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item={true} xs={2} sm={4} md={4}>
              <StatisticsBox
                dashboard={props.dashboard}
                stylesHistorial={props.stylesHistorial}
                stylesText={props.stylesText}
                styleInfoBox={props.styleInfoBox}
                titulo={t('stepsDay')}
                data={steps.items}
                dataSetsNames={[t('steps')]}
                tipoChart="line"
              />
            </Grid>

            <Grid item={true} xs={2} sm={4} md={4}>
              <StatisticsBox
                dashboard={props.dashboard}
                stylesHistorial={props.stylesHistorial}
                styleInfoBox={props.styleInfoBox}
                stylesText={props.stylesText}
                titulo={t('intensityDay')}
                data={intensity.items}
                dataSetsNames={[
                  t('SedentaryMin'),
                  t('LightlyActiveMin'),
                  t('FairlyActiveMin'),
                  t('VeryActiveMin'),
                ]}
                tipoChart="stackedBar"
              />
            </Grid>

            <Grid item={true} xs={2} sm={4} md={4}>
              <StatisticsBox
                dashboard={props.dashboard}
                stylesHistorial={props.stylesHistorial}
                styleInfoBox={props.styleInfoBox}
                stylesText={props.stylesText}
                titulo={t('restingHeartRate')}
                data={heart.items}
                dataSetsNames={['Heart Rate']}
                tipoChart="line"
              />
            </Grid>

            <Grid item={true} xs={2} sm={4} md={4}>
              <StatisticsBox
                dashboard={props.dashboard}
                stylesHistorial={props.stylesHistorial}
                styleInfoBox={props.styleInfoBox}
                stylesText={props.stylesText}
                titulo={t('hoursSleep')}
                data={sleep.items}
                dataSetsNames={['Sleep']}
                tipoChart="line"
              />
            </Grid>

            <Grid item={true} xs={2} sm={4} md={4}>
              <StatisticsBox
                dashboard={props.dashboard}
                stylesHistorial={props.stylesHistorial}
                styleInfoBox={props.styleInfoBox}
                stylesText={props.stylesText}
                titulo={t('caloriesDay')}
                data={calories.items}
                dataSetsNames={['Calories']}
                tipoChart="line"
              />
            </Grid>
            <Grid item={true} xs={2} sm={4} md={4}>
              <StatisticsBox
                dashboard={props.dashboard}
                stylesHistorial={props.stylesHistorial}
                styleInfoBox={props.styleInfoBox}
                stylesText={props.stylesText}
                titulo={t('SpO2')}
                data={spO2.items}
                dataSetsNames={[t('SpO2')]}
                tipoChart="line"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  )
}
