import React, { useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import { Alert, Box, Snackbar, TextField, Typography } from '@mui/material'
import { ShortDescription } from './ShortDescription'
import { Message } from '../../modules/messenger/models/Message'
import { Searcher } from './Searcher'
import 'dayjs'
import style from './Messenger.module.css'
import { Conversation, ConversationQuery } from '../../modules/messenger/models/Conversation'
import { reduceString } from '../../common/utils/strings'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getMessengerContainer } from '../../container/messenger-module'
import { ConversationService } from '../../modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from '../../modules/messenger'
import { Query, QueryParam } from '../../common/api/Query'
import { ConversationEditor } from './ConversationEditor'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { v4 as uuidv4 } from 'uuid'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import genericStyle from '../../common/utils/generic.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import chatIcon from '../../assets/articles/chatIcon.svg'
import avatar from '../../assets/new-right-menu/profile.svg'

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

export type MessengerProps = {
  id?: string
  conversationID?: string
}

// const themeStyles = makeStyles(() => ({
//   input: {
//     '&.Mui-focused': {
//       '& fieldset.MuiOutlinedInput-notchedOutline': {
//         borderColor: '#a9ba37 !important',
//       },
//     },
//   },
//   inputUnderline: {
//     '&:after': {
//       borderColor: '#a9ba37 !important',
//     },
//   },
//   selectInput: {
//     '& ': {
//       width: '29rem !important',
//     },

//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#a9ba37 !important',
//     },
//   },

//   label: {
//     [`&.${inputLabelClasses.shrink}`]: {
//       color: '#a9ba37',
//       borderColor: '#a9ba37',
//     },
//   },
// }))

export function Messenger(props: MessengerProps): JSX.Element {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [currentConversation, setCurrentConversation] = useState<Conversation>()
  const [chatList, setChatList] = useState<JSX.Element[]>([])
  const [conversationCollection, setConversationCollection] = useState<Conversation[]>([])
  const [originalConversations, setOriginalConversations] = useState<Conversation[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [socket, setWebsocket] = useState<WebSocket>()
  const [loadingChat, setLoadingChat] = useState<boolean>(false)
  const [messages, setMessages] = useState<Message[]>([])
  const [message, setMessage] = useState<string>('')
  const [scrollEl, setScrollEl] = useState<any>()
  const [user, setUser] = useState<UserDTO>(emptyUserDTO())
  const [doctor, setDoctor] = useState<UserDTO>(emptyUserDTO())
  const [openSnack, setOpenSnack] = useState<boolean>(false)
  const [alert, setAlert] = useState<string>('')
  const [name, setName] = useState<idName[]>([])
  const [isLoaded2, setIsLoaded2] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  type idName = {
    id: string
    name: string
  }
  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight
    }
  }, [scrollEl, currentConversation])

  const scrollTo = () => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight
    }
  }

  useEffect(() => {
    if (currentConversation && !socket) {
      let userID = loggedUserService?.get()?.id || ''
      setWebsocket(
        new WebSocket(
          `wss://backend.caremeathome.com/ws?conversationID=${currentConversation?.id}&senderID=${userID}`
        )
      )
    }
  }, [currentConversation])

  let connect = () => {
    //console.log('connecting')

    if (socket) {
      socket.onopen = () => {
        // console.log('Successfully Connected')
      }
    }

    if (socket) {
      socket.onmessage = (msg) => {
        if (currentConversation) {
          var m = JSON.parse(msg.data)
          const conversation = cloneDeep<Conversation>(currentConversation)
          let mAux = messages
          mAux.push(
            new Message({
              id: uuidv4(),
              text: m.message,
              authorID: m.senderID,
              conversationID: m.conversationID,
              createdAt: new Date(),
              senderID: loggedUserService?.get()?.id || '',
            })
          )
          conversation.messages = mAux
          setCurrentConversation(conversation)
          setMessages(mAux)
          scrollTo()
        }
      }
    }

    if (socket) {
      socket.onclose = (event) => {
        console.log('Socket Closed Connection: ')
      }
    }

    if (socket) {
      socket.onerror = (error) => {
        console.log('Socket Error: ', error)
      }
    }
  }

  useEffect(() => {
    if (socket) {
      connect()
    }
  }, [socket])

  useEffect(() => {
    if (!loggedUser) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      if (!res) return
      setUser(res)
    })
  }, [loggedUser])

  useEffect(() => {
    if (!user) return
    userService.getByID(user.assignedID).subscribe((res) => {
      if (!res) return
      setDoctor(res)
    })
  }, [user])

  useEffect(() => {
    if (!isLoading || !loggedUser?.id) {
      return
    }
    // console.log(loggedUser)
    conversationsService
      .getFilteredItems(
        new Query({
          query: [new QueryParam<ConversationQuery>('userID10R2', loggedUser?.id || '')],
        })
      )
      .subscribe((res) => {
        setChatList(res.map((c, i) => conversationToShortDescription(c, i)))
        setConversationCollection(res)
        const filter = res.filter((item) => item.userID === props.id)
        if (filter.length > 0) {
          setCurrentConversation(filter[0])
          setMessages(filter[0].messages)
        }
        //console.log(res)
        setOriginalConversations(res)
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    // console.log(conversationCollection)

    let arr = new Array(...name)
    conversationCollection.map((c) => {
      if (typeof c.userID !== 'undefined') {
        userService.getByID(c.userID).subscribe((res) => {
          arr.push(
            Object.assign({ ...arr }, { id: c.userID, name: res?.firstName + ' ' + res?.lastName })
          )
          setName(arr)
        })
      }
    })

    const mapResult = [...conversationCollection].map((c, i) =>
      conversationToShortDescription(c, i)
    )
    setChatList(mapResult)
    replaceOriginalConversations()
    setIsLoading(false)
  }, [conversationCollection])

  useEffect(() => {
    //  console.log(name)
    setChatList(conversationCollection.map((c, i) => conversationToShortDescription(c, i)))
  }, [isLoaded2])

  const orderMessagesByCreationDate = (messages: Message[]): Message[] =>
    [...messages].sort(
      (m1, m2) => new Date(m1.createdAt).getTime() - new Date(m2.createdAt).getTime()
    )

  const handleShortDescription = (conversationID: string | undefined) => {
    const conversation = conversationCollection.find((c) => c.id === conversationID)
    if (conversation) {
      setMessages(conversation.messages)
      setCurrentConversation(conversation)
      scrollTo()
    }
  }

  const conversationToShortDescription = (conversation: Conversation, i: number) => {
    const messages = orderMessagesByCreationDate(conversation.messages)
    const lengthLimit = 70
    const lastMessage =
      conversation?.messages?.length === 0
        ? ''
        : reduceString(messages[messages?.length - 1].text, lengthLimit)
    // console.log('doctor', user)
    const nameX = name.find((element) => element.id === conversation.userID)?.name
    if (!nameX) {
      setIsLoaded2(!isLoaded2)
    }
    const conversationName =
      conversation.userID === loggedUser?.id ? conversation.name : nameX || ''

    return (
      <ShortDescription
        key={conversation.id}
        conversationID={conversation.id}
        conversationName={conversationName}
        avatarUrl={'avatarUrl'}
        lastMessage={lastMessage}
        handlerConversation={handleShortDescription}
        isCurrentConversation={conversation.id === currentConversation?.id}
      />
    )
  }

  const replaceOriginalConversations = () => {
    if (!originalConversations) {
      return
    }
    const newOriginalConversations = [...originalConversations]
    conversationCollection.forEach((c) => {
      const index = newOriginalConversations.findIndex((o) => o.name === c.name)
      index !== -1 && newOriginalConversations.splice(index, 1, c)
    })
    setOriginalConversations(newOriginalConversations)
  }

  const handleSearch = (s: string) => {
    if (user.isPatient) {
      const filteredConversations = originalConversations?.filter((c) =>
        c.name.toLowerCase().includes(s)
      )
      filteredConversations && setConversationCollection(filteredConversations)
    } else {
      const r = name.filter((element) => element.name.toLowerCase().includes(s))
      const filteredConversations = originalConversations?.filter((c) =>
        r.find((e) => c.userID === e.id)
      )
      filteredConversations && setConversationCollection(filteredConversations)
      //console.log("resultado buscqueda", filteredConversations)
    }
  }

  const reverseConversationCollection = () =>
    setConversationCollection([...conversationCollection.reverse()])

  const handleNewConversation = (): void => {
    setCurrentConversation(undefined)
    setMessages([])
    setOpenModal(true)
    scrollTo()
  }

  const handleCloseModal = () => setOpenModal(false)

  const handleSaveModal = (c: Conversation, isUpdate: boolean, participantID: string) => {
    setCurrentConversation(undefined)
    setMessages([])
    //console.log(participantID)
    c.doctorID = user.isPatient ? user.assignedID : loggedUser?.id || ''
    c.userID = user.isPatient ? user.id : participantID
    if (c.name === '') {
      setAlert(t('toSmallName'))
      setOpenSnack(true)
    } else if (c.name.length > 50) {
      setAlert(t('toBigName'))
      setOpenSnack(true)
    } else if (participantID !== '') {
      setOpenModal(false)
      conversationsService.add(c).subscribe((res) => {
        if (!res) {
          return
        }
        let convers = conversationCollection
        convers.push(c)
        setConversationCollection(convers)
        setCurrentConversation(res)
      })
      setIsLoading(false)
      scrollTo()
    } else {
      setAlert(t('notSelectedParticipant'))
      setOpenSnack(true)
    }
  }

  const sendMessage = () => {
    if (message !== '') {
      const m2 = new Message({
        id: uuidv4(),
        text: message,
        authorID: loggedUserService.get()?.id || '',
        conversationID: currentConversation?.id || '',
        createdAt: new Date(),
        senderID: loggedUserService?.get()?.id || '',
      })

      if (socket) {
        socket.send(
          JSON.stringify({
            message: message,
            conversationID: currentConversation?.id,
            senderID: loggedUserService?.get()?.id || '',
            authorID: loggedUserService.get()?.id || '',
          })
        )
      }

      const auxMessages: Message[] = messages
      auxMessages.push(m2)
      setMessages(auxMessages)

      conversationsService.addMessage(m2).subscribe((res) => {
        setMessage('')
        scrollTo()
      })
    }
  }

  // const classes = themeStyles()
  // {698}
  return (
    <>
      {!isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: openMenuLeft ? '50px' : '',
          }}
        >
          <Box
            className={genericStyle.pageContainer}
            style={{
              maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
            }}
          >
            <div className={genericStyle.headerContainer}>
              <Typography>{t('conversations')}</Typography>
            </div>
            <Box justifyContent={'flex-start'} className={genericStyle.container}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                sx={{ height: '100%', width: '100%', padding: '50px' }}
              >
                <Box component="section" height="auto" className={style.conversationWidget}>
                  <Box className={style.searcherContainer} component="section">
                    <Searcher
                      handler={handleSearch}
                      reverse={reverseConversationCollection}
                      handleNewConversation={handleNewConversation}
                    />
                  </Box>
                  <Box className={style.chatList} component="section">
                    {chatList}
                  </Box>
                </Box>

                {currentConversation && (
                  <Box className={style.gridPatient2} style={{ height: 650, maxWidth: '75%' }}>
                    {!loadingChat && (
                      <>
                        <div className={style.somePage}>
                          <div className={style.row}>
                            <div className={style.iconColumn}>
                              <img src={avatar} alt={'avatar'} />
                            </div>
                            <div className={style.column}>
                              <div className={style.greenColumn}>
                                <p className={style.chatTitle}>
                                  {' '}
                                  {user.isPatient
                                    ? currentConversation.name
                                    : name.find((e) => e.id === currentConversation.userID)?.name}
                                </p>
                              </div>
                            </div>
                            <div className={style.iconColumn}>
                              <MoreVertIcon
                                color={'inherit'}
                                cursor="pointer"
                                style={{ alignSelf: 'center' }}
                                fontSize="large"
                              />
                            </div>
                          </div>
                        </div>
                        <Box
                          id="scrollBox"
                          ref={(ref: any) => {
                            setScrollEl(ref)
                          }}
                          className={style.patientBox}
                        >
                          {messages?.map((item, i) => (
                            <>
                              {item.authorID !== loggedUserService.get()?.id ? (
                                <Box className={style.patientBox2} style={{}}>
                                  <Box className={style.patientBox3}>
                                    <Box style={{ margin: 0 }}>
                                      {/*<Avatar
                                          src={'avatarUrl'}
                                          style={{
                                            color: '#ffff',
                                            backgroundColor: '#d2e2ee',
                                            padding: 9,
                                            width: 50,
                                            height: 50,
                                          }}
                                      />*/}
                                      <img
                                        src={avatar}
                                        alt={'avatar'}
                                        style={{
                                          padding: '24px 9px 0 9px',
                                          width: 80,
                                          height: 80,
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                  <Box
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      width: '100%',
                                      marginLeft: '-6%',
                                    }}
                                  >
                                    <Box
                                      style={{
                                        fontSize: 10,
                                        marginBottom: '1%',
                                        color: 'grey',
                                      }}
                                    />
                                    <Box
                                      style={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #ADB84E',
                                        color: '#474747',
                                        padding: 11,
                                        fontSize: 16,
                                        borderRadius: 32,
                                        borderBottomLeftRadius: 0,
                                        flexWrap: 'wrap',
                                        wordWrap: 'break-word',
                                        marginLeft: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {item.text}
                                    </Box>
                                  </Box>
                                </Box>
                              ) : (
                                <Box
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    padding: 20,
                                    paddingTop: 10,
                                  }}
                                >
                                  <Box style={{ width: '30%' }} />
                                  <Box
                                    style={{
                                      backgroundColor: '#a9ba37',
                                      color: 'white',
                                      padding: 11,
                                      fontSize: 16,
                                      borderRadius: 32,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 0,
                                      flexWrap: 'wrap',
                                      wordWrap: 'break-word',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {item.text}
                                  </Box>
                                  <Box style={{ marginLeft: 4 }}>
                                    {/*<Avatar
                                        src={'avatarUrl'}
                                        style={{
                                          color: '#ffff',
                                          backgroundColor: '#d2e2ee',
                                          padding: 0,
                                          width: 50,
                                          height: 50,
                                        }}
                                    />*/}
                                    <img
                                      src={avatar}
                                      alt={'avatar'}
                                      style={{
                                        padding: '24px 9px 0 9px',
                                        width: 80,
                                        height: 80,
                                      }}
                                    />
                                  </Box>
                                </Box>
                              )}
                            </>
                          ))}
                        </Box>
                        <Box
                          className={style.boxMessage}
                          style={{
                            padding: 12,
                            height: '70px',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '0px 0px 32px 32px',
                            backgroundColor: '#a9ba37',
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              backgroundColor: '#f9f9f9',
                              borderRadius: 13,
                              padding: 10,
                              width: '100%',
                              height: 45,
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                              }}
                            >
                              <TextField
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    sendMessage()
                                  }
                                }}
                                placeholder={t('enterMessage')}
                                value={message}
                                variant="standard"
                                style={{
                                  alignSelf: 'center',
                                  width: '100%',
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                // InputLabelProps={{ className: classes.label }}
                                onChange={(e) => setMessage(e.target.value)}
                              />
                            </Box>
                            <img
                              style={{ marginRight: '2%' }}
                              src={chatIcon}
                              alt={'chatIcon'}
                              height={50}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
                {!currentConversation && !chatList && (
                  <Box className={style.emptyConversation}>
                    <h1 className={style.labelCreateOrSelectConversation}>
                      {t('createOrSelectConversation')}
                    </h1>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      ) : (
        ''
      )}
      <Modal
        className={style.modalNewConversation}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConversationEditor
          conversationCollection={conversationCollection}
          conversation={currentConversation}
          handleClose={handleCloseModal}
          handleSave={handleSaveModal}
        />
      </Modal>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnack(false)
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnack(false)
          }}
          severity="error"
          variant={'filled'}
          sx={{ width: '100%' }}
        >
          {alert}
        </Alert>
      </Snackbar>
    </>
  )
}
