import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { emptyFileDTO, FileDTO, fromModel } from '../../modules/files/models/FileDTO'
import { Box } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_RESOURCES } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Alert } from '@material-ui/lab'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import genericStyle from '../../common/utils/generic.module.css'
import style from './Resources.module.css'
import { dataToBase64 } from '../../common/utils/file'
import { inputLabelClasses, Typography } from '@mui/material'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Input, InputTextDescription } from './userProfileStylesMui'

type EditorProps = {
  id?: string
}

enum ResourceErrors {
  NO_FILE = 'noFile',
}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
// const themeStyles = makeStyles(() => ({
//   input: {
//     '&.Mui-focused': {
//       '& fieldset.MuiOutlinedInput-notchedOutline': {
//         borderColor: '#a9ba37 !important',
//       },
//     },
//     '&:[type="file"]': {
//       width: '100%',
//       backgroundColor: 'red',
//     },
//   },
//   inputUnderline: {
//     '&:after': {
//       borderColor: '#a9ba37 !important',
//     },
//   },
//   selectInput: {
//     '& ': {
//       width: '29rem !important',
//     },

//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#a9ba37 !important',
//     },
//   },

//   label: {
//     [`&.${inputLabelClasses.shrink}`]: {
//       color: '#a9ba37',
//       marginTop: '-0.5%',
//       backgroundColor: 'white',
//       borderColor: '#a9ba37',
//     },
//   },
// }))

export function Editor(props: EditorProps) {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()

  const [resource, setResource] = useState<FileDTO>(emptyFileDTO(loggedUser?.id))
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const [fileName, setFileName] = useState<string>('')
  const innerWidth = window.innerWidth

  // console.log('resource', resource)
  useEffect(() => {
    if (!props.id) {
      return
    }
    fileService.getByID(props.id).subscribe((res) => res && setResource(fromModel(res)))
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setResource(Object.assign({ ...resource }, { [e.target.name]: e.target.value }))

  const handleFileInput = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files
    if (!files?.length) {
      return
    }

    const data = await dataToBase64(files[0])
    const fileUploaded = files[0]
    setFileName(fileUploaded.name)
    setResource(Object.assign({ ...resource }, { data, size: data.length }))
  }

  const goBack = () => navigate(ROUTE_RESOURCES)

  const validateResource = (): boolean => {
    if (!resource.data) {
      setErrorMessage(ResourceErrors.NO_FILE)
      return false
    }

    setErrorMessage('')
    return true
  }

  const saveResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateResource()) {
      return
    }
    fileService.add(resource).subscribe(() => {
      if (props.id) {
        statusService.sendStatus({ variant: 'success' })
      }
      goBack()
    })
  }

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (e: any) => {
    e.preventDefault()
    hiddenFileInput.current?.click()
  }

  // const classes = themeStyles()
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: innerWidth > 900 ? (openMenuLeft ? '50px' : '') : '',
      }}
    >
      <Box
        style={{
          maxWidth:
            innerWidth > 900 ? (openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)') : '95%',
          position: 'relative',
        }}
        className={genericStyle.pageContainer}
      >
        <Box sx={{ height: '100%' }} className={genericStyle.container}>
          <>
            <form
              className={style.form}
              style={{ padding: innerWidth < 1280 ? '40px' : '' }}
              onSubmit={saveResource}
            >
              <Box mb={3}>
                <Typography
                  sx={{
                    marginLeft: 1,
                    marginBottom: 1,
                    fontFamily: 'Montserrat-SemiBold',
                    color: '#ADB84E',
                  }}
                >
                  {t('name')}
                </Typography>
                <Input
                  fullWidth
                  name={'name'}
                  value={resource.name}
                  label={''}
                  type={'text'}
                  onChange={handleInput}
                  rows={undefined}
                  required={true}
                />
              </Box>
              <Box mb={3}>
                <Typography
                  sx={{
                    marginLeft: 1,
                    marginBottom: 1,
                    fontFamily: 'Montserrat-SemiBold',
                    color: '#ADB84E',
                  }}
                >
                  {t('description')}
                </Typography>
                <InputTextDescription
                  fullWidth
                  name={'description'}
                  value={resource.description}
                  label={''}
                  type={'text'}
                  onChange={handleInput}
                  multiline
                  rows={4}
                  required={false}
                />
              </Box>

              <Box mb={3}>
                <Typography
                  sx={{
                    marginLeft: 1,
                    marginBottom: 1,
                    fontFamily: 'Montserrat-SemiBold',
                    color: '#ADB84E',
                  }}
                >
                  {t('pdfFile')}
                </Typography>
                <Box className={style.buttonFileContainer}>
                  <button
                    style={{
                      fontSize: innerWidth < 1000 ? '10px' : '15px',
                      width: innerWidth < 1000 ? 'auto' : '',
                    }}
                    className={style.buttonFile}
                    onClick={handleClick}
                  >
                    {t('selectFile')}
                  </button>
                  <input
                    onChange={handleFileInput}
                    style={{ display: 'none' }}
                    id="pdfFile"
                    type={'file'}
                    ref={hiddenFileInput}
                  />
                  <Typography
                    sx={{ fontSize: innerWidth < 1000 ? '12px' : '18px' }}
                    className={style.fileName}
                  >
                    {fileName ? fileName : t('nothingSelected')}
                  </Typography>
                </Box>
              </Box>

              {errorMessage && (
                <Box mb={3}>
                  <Alert severity="warning" key="errorMessage" id="errorMessage">
                    {t(errorMessage)}
                  </Alert>
                </Box>
              )}

              <Box display="flex" justifyContent="flex-end">
                <Box mr={4}>
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    type={'submit'}
                    label={t('save')}
                    handler={() => {}}
                  />
                </Box>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={goBack}
                />
              </Box>
            </form>
          </>
        </Box>
      </Box>
    </div>
  )
}
