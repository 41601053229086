import React, { useEffect, useState } from 'react'
import './App.css'
import { Box } from '@mui/material'
import { Main } from './pages/layout'
import Login from 'Login'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { getUserContainer } from './container/user-module'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from './modules/users'
import { LoggedUser } from './modules/users/models/LoggedUser'
import { useTranslation } from 'react-i18next'
import './assets/scss/style.scss'
import CustomAppBar from 'components/header/CustomAppBar'
import CustomDrawer from 'components/header-component/CustomDrawer'
import { navigate } from '@reach/router'
import {
  ROUTE_ARTICLES,
  ROUTE_CALENDAR,
  ROUTE_COOKIES_POLICY,
  ROUTE_FORM_GENERATOR,
  ROUTE_MESSENGER,
  ROUTE_NEWS_WALL,
  ROUTE_NOTIFICATIONS,
  ROUTE_PATIENTS,
  ROUTE_PRIVACY_POLICY,
  ROUTE_RESOURCES,
  ROUTE_STATISTICS,
  ROUTE_TRAINING,
  ROUTE_TRAININGS,
  ROUTE_USER_FORMS,
} from './routes/routes-constants'
import { getAppContainer, STATUS_SERVICE_KEY } from './container/app'
import { IStatusService } from './common/status/StatusService'
import { useSnackbar } from 'notistack'
import { User } from './modules/users/models/User'
import { UserService } from './modules/users/services/UserService'
import { ProfilePhotoContext } from './common/utils/ProfilePhotoContext'
import { emptyFileDTO, FileDTO } from './modules/files/models/FileDTO'
import { PrivacyPolicy } from './pages/privacy-policy'
import { CookiesPolicy } from './pages/cookies-policy'
import { TitleHeaderContext } from './common/utils/TitleHeaderContext'
import { IsOpenMenuLeftContext } from './common/utils/isOpenMenuLeftContext'
import { useIsOpenMenuLeftContext } from './common/utils/isOpenMenuLeftContext'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)

function App() {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [user, setUser] = useState<LoggedUser | undefined>(loggedUser)
  const [menuSelected, setMenuSelected] = useState<string>(ROUTE_NEWS_WALL)
  const [open, setOpen] = useState<boolean>(false)
  const [userLogged, setUserLogged] = useState<User>()
  const [profilePhoto, setProfilePhoto] = useState<FileDTO>(emptyFileDTO(''))
  const [privacy, setPrivacy] = useState<boolean>(false)
  const [cookies, setCookies] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const { openMenuLeft, setOpenMenuLeft } = useIsOpenMenuLeftContext()

  useEffect(() => {
    loggedUserService
    .getObservable()
    .subscribe((lu) => {
      setUser(lu)
    })
    statusService
    .statusObservable()
    .subscribe((s) => {
      if (s.variant === 'success') {
        enqueueSnackbar(t('changesWereSaved'), { variant: s.variant })
      } else if (s.variant === 'warning') {
        enqueueSnackbar(t(s.message), { variant: s.variant })
      } else {
        //enqueueSnackbar(t('anErrorOccured'), { variant: s.variant })
      }
    })
  }, [])


  useEffect(() => {
    if(!loggedUser) return
    userService
    .getByID(loggedUser?.id)
    .subscribe((lu) => {
      setUserLogged(lu)
    })
  }, [user])

  const handlePrivacyPolicy = () => {
    navigate(ROUTE_PRIVACY_POLICY).then()
    setPrivacy(true)
  }

  const handleSetCookies = () => {
    navigate(ROUTE_COOKIES_POLICY).then()
    setCookies(true)
  }

  const toggleDrawer = () => {
    const newOpenState = !open
    setOpen(newOpenState)
    setOpenMenuLeft(newOpenState)
  }

  

  const handleChangeView = (v: string) => {
    switch (v) {
      case 'START':
        setMenuSelected(ROUTE_NOTIFICATIONS)
        navigate(ROUTE_NOTIFICATIONS)
        break
      case 'PATIENTS':
        setMenuSelected(ROUTE_PATIENTS)
        navigate(ROUTE_PATIENTS)
        break
      case 'QUERIES':
        setMenuSelected(ROUTE_MESSENGER)
        navigate(ROUTE_MESSENGER)
        break
      case 'NEWS':
        setMenuSelected(ROUTE_ARTICLES)
        navigate(ROUTE_ARTICLES)
        break
      case 'RESOURCES':
        setMenuSelected(ROUTE_RESOURCES)
        navigate(ROUTE_RESOURCES)
        break
      case 'SURVEYS':
        setMenuSelected(ROUTE_CALENDAR)
        navigate(ROUTE_CALENDAR)
        break
      case 'STATISTICS':
        setMenuSelected(ROUTE_STATISTICS)
        navigate(ROUTE_STATISTICS)
        break
      case 'TRAINING':
        setMenuSelected(userLogged?.isPatient ? ROUTE_TRAINING : ROUTE_TRAININGS)
        navigate(userLogged?.isPatient ? ROUTE_TRAINING : ROUTE_TRAININGS)
        break
      case 'FORM-GENERATOR':
        setMenuSelected(userLogged?.isPatient ? ROUTE_FORM_GENERATOR : ROUTE_FORM_GENERATOR)
        navigate(userLogged?.isPatient ? ROUTE_FORM_GENERATOR : ROUTE_FORM_GENERATOR)
        break
      case 'USER-FORMS':
        setMenuSelected(userLogged?.isPatient ? ROUTE_USER_FORMS : ROUTE_USER_FORMS)
        navigate(userLogged?.isPatient ? ROUTE_USER_FORMS : ROUTE_USER_FORMS)
        break
      default:
        break
    }
  }

  const onLogout = () => {
    setUser(undefined)
  }
  const handleLogin = (e: LoggedUser) => {
    setUser(e);
    handleChangeView('START')
  }

  return (
    <>
      {!user && (
        <>
        { privacy ? (
          <PrivacyPolicy />
        )
        : cookies ? (
          <CookiesPolicy />
        ) : (
          <Login
            handleSetCookies={handleSetCookies}
            handlePrivacyPolicy={handlePrivacyPolicy}
            onLogin={handleLogin}
          />
        )}
        </>
      )}
      {user && (
        <IsOpenMenuLeftContext.Provider value={{ openMenuLeft: open, setOpenMenuLeft: setOpen }}>
          <TitleHeaderContext.Provider value={{ title, setTitle }}>
            <ProfilePhotoContext.Provider value={{ profilePhoto, setProfilePhoto }}>
              <Box className="mainBox">
                <CustomAppBar open={open} toggleDrawer={toggleDrawer} onChange={onLogout} />
                <CustomDrawer
                  open={open}
                  toggleDrawer={toggleDrawer}
                  handleChangeView={handleChangeView}
                  setOpen={setOpen}
                />
                <Box
                  className="backBox"
                  component="main"
                  style={{ overflowX: 'hidden', overflow: 'auto' }}
                >
                  <Main selectedMenu={menuSelected} />
                  <Box width={'100%'}></Box>
                </Box>
              </Box>
            </ProfilePhotoContext.Provider>
          </TitleHeaderContext.Provider>
        </IsOpenMenuLeftContext.Provider>
      )}
    </>
  )
}

export default App
