import { useEffect, useState } from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import homeIcon from '../../assets/new-left-menu/home.svg'
import iconPatient from '../../assets/new-left-menu/patients.svg'
import iconQuery from '../../assets/new-left-menu/consultas.svg'
import iconNews from '../../assets/new-left-menu/news.svg'
import iconResources from '../../assets/new-left-menu/resources.svg'
import iconCalendar from '../../assets/left_menu/icono-calendario.svg'
import iconStadistics from '../../assets/new-left-menu/statistics.svg'
import iconTraining from '../../assets/new-left-menu/training.svg'
import iconForms from '../../assets/new-left-menu/training.svg'

import styles from './ListItems.module.css'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { User } from '../../modules/users/models/User'
import { UserService } from '../../modules/users/services/UserService'
import { ROUTE_ARTICLES, ROUTE_CALENDAR, ROUTE_FORM_GENERATOR, ROUTE_FORM_GENERATOR_FORM, ROUTE_MESSENGER, ROUTE_NEWS_WALL, ROUTE_PATIENTS, ROUTE_RESOURCES, ROUTE_STATISTICS, ROUTE_TRAINING, ROUTE_TRAININGS } from 'routes/routes-constants'

interface ItemProps {
  url: string;
  label: string;
  handle: string;
  icon: string;
  alt: string;
  visible: boolean;
}

type ListItemsProps = {
  handleClick: (type: string) => void
  isOpen: boolean
  setOpen: (b: boolean) => void
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)

const ListItems = (props: ListItemsProps) => {
  const { t } = useTranslation()
  const [user, setUser] = useState<User>()
  const loggedUser = loggedUserService.get()
  const pathname = window.location.pathname

  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser.id).subscribe((res) => {
      setUser(res)
    })
  }, [loggedUser])

  const ITEMS: ItemProps[] = [
    {
      label: t('start'),
      url: ROUTE_NEWS_WALL,
      handle: 'START',
      icon: homeIcon,
      alt: "News Wall Icon",
      visible: true
    },
    {
      label: t('patients'),
      url: ROUTE_PATIENTS,
      handle: 'PATIENTS',
      icon: iconPatient,
      alt: "Patients Icon",
      visible: !user?.isPatient
    },
    {
      label: t('queries'),
      url: ROUTE_MESSENGER,
      handle: 'QUERIES',
      icon: iconQuery,
      alt: "Messenger Icon",
      visible: true
    },
    {
      label: t('news'),
      url: ROUTE_ARTICLES,
      handle: 'NEWS',
      icon: iconNews,
      alt: "News Icon",
      visible: true
    },
    {
      label: t('resources2'),
      url: ROUTE_RESOURCES,
      handle: 'RESOURCES',
      icon: iconResources,
      alt: "Resources Icon",
      visible: true
    },
    {
      label: t('Calendario'),
      url: ROUTE_CALENDAR,
      handle: 'SURVEYS',
      icon: iconCalendar,
      alt: "Calendar Icon",
      visible: true
    },
    {
      label: t('statistics'),
      url: ROUTE_STATISTICS,
      handle: 'STATISTICS',
      icon: iconStadistics,
      alt: "Statistics Icon",
      visible: !!(user && user.isPatient)
    },
    {
      label: t('training'),
      url: user?.isPatient ? ROUTE_TRAINING : ROUTE_TRAININGS,
      handle: 'TRAINING',
      icon: iconTraining,
      alt: "Training Icon",
      visible: !!(user && user.isPatient)
    },
    {
      label: t('forms'),
      url: ROUTE_FORM_GENERATOR,
      handle: 'FORM-GENERATOR',
      icon: iconForms,
      alt: "Forms Icon",
      visible: true
    },
    // {
    //   label: t('statistics'),
    //   url: "/statistics",
    //   handle: 'STATISTICS',
    //   icon: iconResources,
    //   alt: "Statistics Icon",
    //   visible: !!(user && !user.isPatient)
    // },
  ]

  return (
    <List
      className={styles.listContainer}
    >
      {
      ITEMS
      .filter((f) => f.visible)
      .map((item: ItemProps) => {
        return (
          <ListItemButton
            key={item.label}
            className={styles.listItem}
            onClick={() => {
              props.handleClick(item.handle)
            }}
          >
            <ListItemIcon className={styles.iconLayer}>
              <img className={`${styles.icon} ${item.url.includes(pathname) && styles.iconActive}`} src={item.icon} alt={item.alt} />
            </ListItemIcon>
            <ListItemText className={`${styles.text} ${item.url.includes(pathname) && styles.textActive}`} primary={item.label} />
          </ListItemButton>
        )
      })}
    </List>
  )
}

export default ListItems
