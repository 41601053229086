import React, { useEffect, useState } from 'react'
import { AppTable, Field } from '../../components/table'
import { Box } from '@mui/material'
import { Actions, Pager } from '../../components/table/types'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { getTrainingContainer } from '../../container/training-module'
import {
  ITRainingService,
  IUserTrainingService,
  TRAINING_SERVICE_KEY,
  USER_TRAINING_SERVICE_KEY,
} from '../../modules/training'
import { Query } from '../../common/api/Query'
import { ROUTE_TRAININGS } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import {
  emptyUserTrainingDTO,
  UserTrainingDTO,
} from '../../modules/training/models/UserTrainingDTO'
import { TrainingDTO } from '../../modules/training/models/TrainingDTO'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { User } from '../../modules/users/models/User'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { reduceString } from '../../common/utils/strings'

const userContainer = getUserContainer()
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const trainingService = getTrainingContainer().get<ITRainingService>(TRAINING_SERVICE_KEY)

type TableTrainingsProps = {
  id?: string
}

export function Table(props: TableTrainingsProps): JSX.Element {
  const { t } = useTranslation('translation')
  const loggedUser = loggedUserService.get()
  const [user, setUser] = useState<User>()
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [training, setTraining] = useState<TrainingDTO[]>([])
  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser?.id).subscribe((res) => {
      res && setUser(res)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!isLoading) return
    trainingService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
        })
      )
      .subscribe((res) => {
        if (!res) return
        setTraining(res.items)
        setCount(res.count)
        setIsLoading(false)
      })
  }, [isLoading])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const handlerSeeTraining = (id: TrainingDTO) => {
    navigate(`${ROUTE_TRAININGS}/${id.id}`)
  }

  const fields: Field<TrainingDTO>[] = [
    {
      name: 'title',
      label: t('title'),
    },
    {
      name: 'description',
      label: t('description'),
      renderFunc: (f, i) => reduceString(i.description, 50),
    },
  ]

  const actions: Actions<TrainingDTO> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: handlerSeeTraining,
        icon: seeIcon,
        label: 'see',
      },
      /*{
        handler: (ut) => console.log('press'),
        icon: editIcon,
        label: 'edit',
      },
      {
        handler: (ut) => console.log('press'),
        icon: deleteIcon,
        label: 'delete',
      },*/
    ],
  }

  return (
    <Box mt={3}>
      <AppTable
        items={training}
        rowKeyField="id"
        fields={fields}
        actions={actions}
        pager={pager}
        isLoading={isLoading}
      />
    </Box>
  )
}
